/*!
 *
 * Bryntum Scheduler 6.0.1
 *
 * Copyright(c) 2024 Bryntum AB
 * https://bryntum.com/contact
 * https://bryntum.com/license
 *
 */
(function (d, l) {
  var i = typeof exports == "object";
  if (typeof define == "function" && define.amd) define([], l);else if (typeof module == "object" && module.exports) module.exports = l();else {
    var u = l(),
      g = i ? exports : d;
    for (var p in u) g[p] = u[p];
  }
})(typeof self < "u" ? self : this, () => {
  var d = {},
    l = {
      exports: d
    },
    i = Object.defineProperty,
    u = Object.getOwnPropertyDescriptor,
    g = Object.getOwnPropertyNames,
    p = Object.prototype.hasOwnProperty,
    v = (e, t, o) => t in e ? i(e, t, {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: o
    }) : e[t] = o,
    f = (e, t) => {
      for (var o in t) i(e, o, {
        get: t[o],
        enumerable: !0
      });
    },
    b = (e, t, o, n) => {
      if (t && typeof t == "object" || typeof t == "function") for (let a of g(t)) !p.call(e, a) && a !== o && i(e, a, {
        get: () => t[a],
        enumerable: !(n = u(t, a)) || n.enumerable
      });
      return e;
    },
    S = e => b(i({}, "__esModule", {
      value: !0
    }), e),
    E = (e, t, o) => (v(e, typeof t != "symbol" ? t + "" : t, o), o),
    h = {};
  f(h, {
    default: () => F
  }), l.exports = S(h);
  var s = typeof self < "u" ? self : typeof globalThis < "u" ? globalThis : null,
    y = class c {
      static mergeLocales(...t) {
        let o = {};
        return t.forEach(n => {
          Object.keys(n).forEach(a => {
            typeof n[a] == "object" ? o[a] = {
              ...o[a],
              ...n[a]
            } : o[a] = n[a];
          });
        }), o;
      }
      static trimLocale(t, o) {
        let n = (a, r) => {
          t[a] && (r ? t[a][r] && delete t[a][r] : delete t[a]);
        };
        Object.keys(o).forEach(a => {
          Object.keys(o[a]).length > 0 ? Object.keys(o[a]).forEach(r => n(a, r)) : n(a);
        });
      }
      static normalizeLocale(t, o) {
        if (!t) throw new Error('"nameOrConfig" parameter can not be empty');
        if (typeof t == "string") {
          if (!o) throw new Error('"config" parameter can not be empty');
          o.locale ? o.name = t || o.name : o.localeName = t;
        } else o = t;
        let n = {};
        if (o.name || o.locale) n = Object.assign({
          localeName: o.name
        }, o.locale), o.desc && (n.localeDesc = o.desc), o.code && (n.localeCode = o.code), o.path && (n.localePath = o.path);else {
          if (!o.localeName) throw new Error(`"config" parameter doesn't have "localeName" property`);
          n = Object.assign({}, o);
        }
        for (let a of ["name", "desc", "code", "path"]) n[a] && delete n[a];
        if (!n.localeName) throw new Error("Locale name can not be empty");
        return n;
      }
      static get locales() {
        return s.bryntum.locales || {};
      }
      static set locales(t) {
        s.bryntum.locales = t;
      }
      static get localeName() {
        return s.bryntum.locale || "En";
      }
      static set localeName(t) {
        s.bryntum.locale = t || c.localeName;
      }
      static get locale() {
        return c.localeName && this.locales[c.localeName] || this.locales.En || Object.values(this.locales)[0] || {
          localeName: "",
          localeDesc: "",
          localeCoode: ""
        };
      }
      static publishLocale(t, o) {
        let {
            locales: n
          } = s.bryntum,
          a = c.normalizeLocale(t, o),
          {
            localeName: r
          } = a;
        return !n[r] || o === !0 ? n[r] = a : n[r] = this.mergeLocales(n[r] || {}, a || {}), n[r];
      }
    };
  E(y, "skipLocaleIntegrityCheck", !1);
  var m = y;
  s.bryntum = s.bryntum || {}, s.bryntum.locales = s.bryntum.locales || {}, m._$name = "LocaleHelper";
  var w = {
      localeName: "En",
      localeDesc: "English (US)",
      localeCode: "en-US",
      Object: {
        Yes: "Yes",
        No: "No",
        Cancel: "Cancel",
        Ok: "OK",
        Week: "Week",
        None: "None"
      },
      ColorPicker: {
        noColor: "No color"
      },
      Combo: {
        noResults: "No results",
        recordNotCommitted: "Record could not be added",
        addNewValue: e => `Add ${e}`
      },
      FilePicker: {
        file: "File"
      },
      Field: {
        badInput: "Invalid field value",
        patternMismatch: "Value should match a specific pattern",
        rangeOverflow: e => `Value must be less than or equal to ${e.max}`,
        rangeUnderflow: e => `Value must be greater than or equal to ${e.min}`,
        stepMismatch: "Value should fit the step",
        tooLong: "Value should be shorter",
        tooShort: "Value should be longer",
        typeMismatch: "Value is required to be in a special format",
        valueMissing: "This field is required",
        invalidValue: "Invalid field value",
        minimumValueViolation: "Minimum value violation",
        maximumValueViolation: "Maximum value violation",
        fieldRequired: "This field is required",
        validateFilter: "Value must be selected from the list"
      },
      DateField: {
        invalidDate: "Invalid date input"
      },
      DatePicker: {
        gotoPrevYear: "Go to previous year",
        gotoPrevMonth: "Go to previous month",
        gotoNextMonth: "Go to next month",
        gotoNextYear: "Go to next year"
      },
      NumberFormat: {
        locale: "en-US",
        currency: "USD"
      },
      DurationField: {
        invalidUnit: "Invalid unit"
      },
      TimeField: {
        invalidTime: "Invalid time input"
      },
      TimePicker: {
        hour: "Hour",
        minute: "Minute",
        second: "Second"
      },
      List: {
        loading: "Loading...",
        selectAll: "Select All"
      },
      GridBase: {
        loadMask: "Loading...",
        syncMask: "Saving changes, please wait..."
      },
      PagingToolbar: {
        firstPage: "Go to first page",
        prevPage: "Go to previous page",
        page: "Page",
        nextPage: "Go to next page",
        lastPage: "Go to last page",
        reload: "Reload current page",
        noRecords: "No records to display",
        pageCountTemplate: e => `of ${e.lastPage}`,
        summaryTemplate: e => `Displaying records ${e.start} - ${e.end} of ${e.allCount}`
      },
      PanelCollapser: {
        Collapse: "Collapse",
        Expand: "Expand"
      },
      Popup: {
        close: "Close"
      },
      UndoRedo: {
        Undo: "Undo",
        Redo: "Redo",
        UndoLastAction: "Undo last action",
        RedoLastAction: "Redo last undone action",
        NoActions: "No items in the undo queue"
      },
      FieldFilterPicker: {
        equals: "equals",
        doesNotEqual: "does not equal",
        isEmpty: "empty",
        isNotEmpty: "not empty",
        contains: "contains",
        doesNotContain: "does not contain",
        startsWith: "starts with",
        endsWith: "ends with",
        isOneOf: "one of",
        isNotOneOf: "not one of",
        isGreaterThan: "greater than",
        isLessThan: "less than",
        isGreaterThanOrEqualTo: "greater or equals",
        isLessThanOrEqualTo: "less or equals",
        isBetween: "between",
        isNotBetween: "not between",
        isBefore: "before",
        isAfter: "after",
        isToday: "today",
        isTomorrow: "tomorrow",
        isYesterday: "yesterday",
        isThisWeek: "this week",
        isNextWeek: "next week",
        isLastWeek: "last week",
        isThisMonth: "this month",
        isNextMonth: "next month",
        isLastMonth: "last month",
        isThisYear: "this year",
        isNextYear: "next year",
        isLastYear: "last year",
        isYearToDate: "year to date",
        isTrue: "true",
        isFalse: "false",
        selectAProperty: "Select property",
        selectAnOperator: "Select operator",
        caseSensitive: "Case-sensitive",
        and: "and",
        dateFormat: "D/M/YY",
        selectValue: "Select value",
        selectOneOrMoreValues: "Select value(s)",
        enterAValue: "Enter value",
        enterANumber: "Enter number",
        selectADate: "Select date",
        selectATime: "Select time"
      },
      FieldFilterPickerGroup: {
        addFilter: "Add filter"
      },
      DateHelper: {
        locale: "en-US",
        weekStartDay: 0,
        nonWorkingDays: {
          0: !0,
          6: !0
        },
        weekends: {
          0: !0,
          6: !0
        },
        unitNames: [{
          single: "millisecond",
          plural: "ms",
          abbrev: "ms"
        }, {
          single: "second",
          plural: "seconds",
          abbrev: "s"
        }, {
          single: "minute",
          plural: "minutes",
          abbrev: "min"
        }, {
          single: "hour",
          plural: "hours",
          abbrev: "h"
        }, {
          single: "day",
          plural: "days",
          abbrev: "d"
        }, {
          single: "week",
          plural: "weeks",
          abbrev: "w"
        }, {
          single: "month",
          plural: "months",
          abbrev: "mon"
        }, {
          single: "quarter",
          plural: "quarters",
          abbrev: "q"
        }, {
          single: "year",
          plural: "years",
          abbrev: "yr"
        }, {
          single: "decade",
          plural: "decades",
          abbrev: "dec"
        }],
        unitAbbreviations: [["mil"], ["s", "sec"], ["m", "min"], ["h", "hr"], ["d"], ["w", "wk"], ["mo", "mon", "mnt"], ["q", "quar", "qrt"], ["y", "yr"], ["dec"]],
        parsers: {
          L: "MM/DD/YYYY",
          LT: "HH:mm A",
          LTS: "HH:mm:ss A"
        },
        ordinalSuffix: e => {
          let t = ["11", "12", "13"].find(n => e.endsWith(n)),
            o = "th";
          if (!t) {
            let n = e[e.length - 1];
            o = {
              1: "st",
              2: "nd",
              3: "rd"
            }[n] || "th";
          }
          return e + o;
        }
      }
    },
    T = m.publishLocale(w),
    C = new String(),
    D = {
      localeName: "En",
      localeDesc: "English (US)",
      localeCode: "en-US",
      ColumnPicker: {
        column: "Column",
        columnsMenu: "Columns",
        hideColumn: "Hide column",
        hideColumnShort: "Hide",
        newColumns: "New columns"
      },
      Filter: {
        applyFilter: "Apply filter",
        filter: "Filter",
        editFilter: "Edit filter",
        on: "On",
        before: "Before",
        after: "After",
        equals: "Equals",
        lessThan: "Less than",
        moreThan: "More than",
        removeFilter: "Remove filter",
        disableFilter: "Disable filter"
      },
      FilterBar: {
        enableFilterBar: "Show filter bar",
        disableFilterBar: "Hide filter bar"
      },
      Group: {
        group: "Group",
        groupAscending: "Group ascending",
        groupDescending: "Group descending",
        groupAscendingShort: "Ascending",
        groupDescendingShort: "Descending",
        stopGrouping: "Stop grouping",
        stopGroupingShort: "Stop"
      },
      HeaderMenu: {
        moveBefore: e => `Move before "${e}"`,
        moveAfter: e => `Move after "${e}"`,
        collapseColumn: "Collapse column",
        expandColumn: "Expand column"
      },
      ColumnRename: {
        rename: "Rename"
      },
      MergeCells: {
        mergeCells: "Merge cells",
        menuTooltip: "Merge cells with same value when sorted by this column"
      },
      Search: {
        searchForValue: "Search for value"
      },
      Sort: {
        sort: "Sort",
        sortAscending: "Sort ascending",
        sortDescending: "Sort descending",
        multiSort: "Multi sort",
        removeSorter: "Remove sorter",
        addSortAscending: "Add ascending sorter",
        addSortDescending: "Add descending sorter",
        toggleSortAscending: "Change to ascending",
        toggleSortDescending: "Change to descending",
        sortAscendingShort: "Ascending",
        sortDescendingShort: "Descending",
        removeSorterShort: "Remove",
        addSortAscendingShort: "+ Ascending",
        addSortDescendingShort: "+ Descending"
      },
      Split: {
        split: "Split",
        unsplit: "Unsplit",
        horizontally: "Horizontally",
        vertically: "Vertically",
        both: "Both"
      },
      LockRows: {
        lockRow: "Lock row",
        unlockRow: "Unlock row"
      },
      Column: {
        columnLabel: e => `${e.text ? `${e.text} column. ` : ""}SPACE for context menu${e.sortable ? ", ENTER to sort" : ""}`,
        cellLabel: C
      },
      Checkbox: {
        toggleRowSelect: "Toggle row selection",
        toggleSelection: "Toggle selection of entire dataset"
      },
      RatingColumn: {
        cellLabel: e => {
          var t;
          return `${e.text ? e.text : ""} ${(t = e.location) != null && t.record ? `rating : ${e.location.record.get(e.field) || 0}` : ""}`;
        }
      },
      GridBase: {
        loadFailedMessage: "Data loading failed!",
        syncFailedMessage: "Data synchronization failed!",
        unspecifiedFailure: "Unspecified failure",
        networkFailure: "Network error",
        parseFailure: "Failed to parse server response",
        serverResponse: "Server response:",
        noRows: "No records to display",
        moveColumnLeft: "Move to left section",
        moveColumnRight: "Move to right section",
        moveColumnTo: e => `Move column to ${e}`
      },
      CellMenu: {
        removeRow: "Delete"
      },
      RowCopyPaste: {
        copyRecord: "Copy",
        cutRecord: "Cut",
        pasteRecord: "Paste",
        rows: "rows",
        row: "row"
      },
      CellCopyPaste: {
        copy: "Copy",
        cut: "Cut",
        paste: "Paste"
      },
      PdfExport: {
        "Waiting for response from server": "Waiting for response from server...",
        "Export failed": "Export failed",
        "Server error": "Server error",
        "Generating pages": "Generating pages...",
        "Click to abort": "Cancel"
      },
      ExportDialog: {
        width: "40em",
        labelWidth: "12em",
        exportSettings: "Export settings",
        export: "Export",
        printSettings: "Print settings",
        print: "Print",
        exporterType: "Control pagination",
        cancel: "Cancel",
        fileFormat: "File format",
        rows: "Rows",
        alignRows: "Align rows",
        columns: "Columns",
        paperFormat: "Paper format",
        orientation: "Orientation",
        repeatHeader: "Repeat header"
      },
      ExportRowsCombo: {
        all: "All rows",
        visible: "Visible rows"
      },
      ExportOrientationCombo: {
        portrait: "Portrait",
        landscape: "Landscape"
      },
      SinglePageExporter: {
        singlepage: "Single page"
      },
      MultiPageExporter: {
        multipage: "Multiple pages",
        exportingPage: ({
          currentPage: e,
          totalPages: t
        }) => `Exporting page ${e}/${t}`
      },
      MultiPageVerticalExporter: {
        multipagevertical: "Multiple pages (vertical)",
        exportingPage: ({
          currentPage: e,
          totalPages: t
        }) => `Exporting page ${e}/${t}`
      },
      RowExpander: {
        loading: "Loading",
        expand: "Expand",
        collapse: "Collapse"
      },
      TreeGroup: {
        group: "Group by",
        stopGrouping: "Stop grouping",
        stopGroupingThisColumn: "Ungroup column"
      }
    },
    M = m.publishLocale(D),
    x = {
      localeName: "En",
      localeDesc: "English (US)",
      localeCode: "en-US",
      Object: {
        newEvent: "New event"
      },
      ResourceInfoColumn: {
        eventCountText: e => e + " event" + (e !== 1 ? "s" : "")
      },
      Dependencies: {
        from: "From",
        to: "To",
        valid: "Valid",
        invalid: "Invalid"
      },
      DependencyType: {
        SS: "SS",
        SF: "SF",
        FS: "FS",
        FF: "FF",
        StartToStart: "Start-to-Start",
        StartToEnd: "Start-to-Finish",
        EndToStart: "Finish-to-Start",
        EndToEnd: "Finish-to-Finish",
        short: ["SS", "SF", "FS", "FF"],
        long: ["Start-to-Start", "Start-to-Finish", "Finish-to-Start", "Finish-to-Finish"]
      },
      DependencyEdit: {
        From: "From",
        To: "To",
        Type: "Type",
        Lag: "Lag",
        "Edit dependency": "Edit dependency",
        Save: "Save",
        Delete: "Delete",
        Cancel: "Cancel",
        StartToStart: "Start to Start",
        StartToEnd: "Start to End",
        EndToStart: "End to Start",
        EndToEnd: "End to End"
      },
      EventEdit: {
        Name: "Name",
        Resource: "Resource",
        Start: "Start",
        End: "End",
        Save: "Save",
        Delete: "Delete",
        Cancel: "Cancel",
        "Edit event": "Edit",
        Repeat: "Repeat"
      },
      EventDrag: {
        eventOverlapsExisting: "Event overlaps existing event for this resource",
        noDropOutsideTimeline: "Event may not be dropped completely outside the timeline"
      },
      SchedulerBase: {
        "Add event": "Add event",
        "Delete event": "Delete",
        "Unassign event": "Unassign",
        color: "Color"
      },
      TimeAxisHeaderMenu: {
        pickZoomLevel: "Zoom",
        activeDateRange: "Date range",
        startText: "Start date",
        endText: "End date",
        todayText: "Today"
      },
      EventCopyPaste: {
        copyEvent: "Copy",
        cutEvent: "Cut",
        pasteEvent: "Paste"
      },
      EventFilter: {
        filterEvents: "Filter tasks",
        byName: "By name"
      },
      TimeRanges: {
        showCurrentTimeLine: "Show current timeline"
      },
      PresetManager: {
        secondAndMinute: {
          displayDateFormat: "ll LTS",
          name: "Seconds"
        },
        minuteAndHour: {
          topDateFormat: "ddd MM/DD, hA",
          displayDateFormat: "ll LST"
        },
        hourAndDay: {
          topDateFormat: "ddd MM/DD",
          middleDateFormat: "LST",
          displayDateFormat: "ll LST",
          name: "Day"
        },
        day: {
          name: "Day/hours"
        },
        week: {
          name: "Week/hours"
        },
        dayAndWeek: {
          displayDateFormat: "ll LST",
          name: "Week/days"
        },
        dayAndMonth: {
          name: "Month"
        },
        weekAndDay: {
          displayDateFormat: "ll LST",
          name: "Week"
        },
        weekAndMonth: {
          name: "Weeks"
        },
        weekAndDayLetter: {
          name: "Weeks/weekdays"
        },
        weekDateAndMonth: {
          name: "Months/weeks"
        },
        monthAndYear: {
          name: "Months"
        },
        year: {
          name: "Years"
        },
        manyYears: {
          name: "Multiple years"
        }
      },
      RecurrenceConfirmationPopup: {
        "delete-title": "You are deleting an event",
        "delete-all-message": "Do you want to delete all occurrences of this event?",
        "delete-further-message": "Do you want to delete this and all future occurrences of this event, or only the selected occurrence?",
        "delete-further-btn-text": "Delete All Future Events",
        "delete-only-this-btn-text": "Delete Only This Event",
        "update-title": "You are changing a repeating event",
        "update-all-message": "Do you want to change all occurrences of this event?",
        "update-further-message": "Do you want to change only this occurrence of the event, or this and all future occurrences?",
        "update-further-btn-text": "All Future Events",
        "update-only-this-btn-text": "Only This Event",
        Yes: "Yes",
        Cancel: "Cancel",
        width: 600
      },
      RecurrenceLegend: {
        " and ": " and ",
        Daily: "Daily",
        "Weekly on {1}": ({
          days: e
        }) => `Weekly on ${e}`,
        "Monthly on {1}": ({
          days: e
        }) => `Monthly on ${e}`,
        "Yearly on {1} of {2}": ({
          days: e,
          months: t
        }) => `Yearly on ${e} of ${t}`,
        "Every {0} days": ({
          interval: e
        }) => `Every ${e} days`,
        "Every {0} weeks on {1}": ({
          interval: e,
          days: t
        }) => `Every ${e} weeks on ${t}`,
        "Every {0} months on {1}": ({
          interval: e,
          days: t
        }) => `Every ${e} months on ${t}`,
        "Every {0} years on {1} of {2}": ({
          interval: e,
          days: t,
          months: o
        }) => `Every ${e} years on ${t} of ${o}`,
        position1: "the first",
        position2: "the second",
        position3: "the third",
        position4: "the fourth",
        position5: "the fifth",
        "position-1": "the last",
        day: "day",
        weekday: "weekday",
        "weekend day": "weekend day",
        daysFormat: ({
          position: e,
          days: t
        }) => `${e} ${t}`
      },
      RecurrenceEditor: {
        "Repeat event": "Repeat event",
        Cancel: "Cancel",
        Save: "Save",
        Frequency: "Frequency",
        Every: "Every",
        DAILYintervalUnit: "day(s)",
        WEEKLYintervalUnit: "week(s)",
        MONTHLYintervalUnit: "month(s)",
        YEARLYintervalUnit: "year(s)",
        Each: "Each",
        on: "On",
        the: "The",
        "On the": "On the",
        "End repeat": "End repeat",
        "time(s)": "time(s)",
        Days: "Days",
        Months: "Months"
      },
      RecurrenceDaysCombo: {
        day: "day",
        weekday: "weekday",
        "weekend day": "weekend day"
      },
      RecurrencePositionsCombo: {
        position1: "first",
        position2: "second",
        position3: "third",
        position4: "fourth",
        position5: "fifth",
        "position-1": "last"
      },
      RecurrenceStopConditionCombo: {
        Never: "Never",
        After: "After",
        "On date": "On date"
      },
      RecurrenceFrequencyCombo: {
        None: "No repeat",
        Daily: "Daily",
        Weekly: "Weekly",
        Monthly: "Monthly",
        Yearly: "Yearly"
      },
      RecurrenceCombo: {
        None: "None",
        Custom: "Custom..."
      },
      Summary: {
        "Summary for": e => `Summary for ${e}`
      },
      ScheduleRangeCombo: {
        completeview: "Complete schedule",
        currentview: "Visible schedule",
        daterange: "Date range",
        completedata: "Complete schedule (for all events)"
      },
      SchedulerExportDialog: {
        "Schedule range": "Schedule range",
        "Export from": "From",
        "Export to": "To"
      },
      ExcelExporter: {
        "No resource assigned": "No resource assigned"
      },
      CrudManagerView: {
        serverResponseLabel: "Server response:"
      },
      DurationColumn: {
        Duration: "Duration"
      }
    },
    F = m.publishLocale(x);
  if (typeof l.exports == "object" && typeof d == "object") {
    var k = (e, t, o, n) => {
      if (t && typeof t == "object" || typeof t == "function") for (let a of Object.getOwnPropertyNames(t)) !Object.prototype.hasOwnProperty.call(e, a) && a !== o && Object.defineProperty(e, a, {
        get: () => t[a],
        enumerable: !(n = Object.getOwnPropertyDescriptor(t, a)) || n.enumerable
      });
      return e;
    };
    l.exports = k(l.exports, d);
  }
  return l.exports;
});